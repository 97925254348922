
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Tag } from 'ant-design-vue';

@Component({
    name: 'TwoRowWithTag',
    components: { Tag },
})
export default class TwoRowWithTag extends Vue {
    @Prop({ default: () => [] }) private tags!: Array<{ name: string; color: string }>;
    @Prop({ default: false }) private isLightVariant!: boolean;
    @Prop({ default: false }) private isLargeVariant!: boolean;

    /* tslint:disable:no-bitwise */
    private hexToRgb(hex: string): string {
        const bigint = parseInt(hex.substring(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return r + ',' + g + ',' + b;
    }
    /* tslint:enable:no-bitwise */

    private getTagStyle(color: string) {
        return {
            color: color,
            border: '1px solid ' + color,
            'background-color': 'rgba(' + this.hexToRgb(color) + ', 0.125)',
        };
    }
}
